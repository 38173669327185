import type {PricingDataContextValue, Site} from '@data/types';
import getPricingInterpolations from '@/utils/pricing';
import {localizePathBySite} from '@/utils/site';
import {
  helpUrl,
  inboxPathAlt,
  linkpopUrl,
  signupName,
  legalPrivacyPath,
  legalTermPath,
  legalCookiesPath,
} from '@/hooks/navigation/utils';
import {appKeys, UrlUtils} from '@/utils/UrlUtils';

import {baseUri} from './constants';

function interpolation(
  currentSite: Site,
  pricingData: PricingDataContextValue,
) {
  const site = currentSite || {};
  const lang = site.languageCode || site.locale?.split('-')[0] || 'en';

  const pricingInterpolations = getPricingInterpolations(pricingData);

  /**
   * URL interpolation via `i18n.ts` custom formatter. Passing these dummy values
   * (e.g. `__BLOG__`) forces us to use the `url()` formatter:
   * `{{blog, url(path: '/')}}` instead of concatenating strings manually like
   * this: `{{site}}/some/path` in `content|settings.json` files.
   */
  const urlInterpolations = UrlUtils._keysToObj(
    appKeys,
    (key) => `__${key.toUpperCase()}__`,
  );

  return {
    ...pricingInterpolations,
    currency: site.currencyCode || 'USD',
    currentYear: new Date().getFullYear(),
    deeplinkFreeTrialName: signupName,
    /**
     * @deprecated Use `help` instead
     */
    helpUrl: helpUrl(site),
    /**
     * @deprecated Use `site` (eventually `brochure`) instead
     */
    home: site
      ? site.pathPrefix
        ? `https://www.shopify.com${localizePathBySite('', site)}`
        : `https://${site.domain}`
      : baseUri,
    inboxPathAlt: inboxPathAlt(site),
    linkpopUrl: linkpopUrl(site),
    legalPrivacyPath: legalPrivacyPath(site),
    legalTermPath: legalTermPath(site),
    legalCookiesPath: legalCookiesPath(site),
    // this is for visuals to display currency
    locale: site ? `${lang}-${site?.countryCode || 'US'}` : 'US',
    shopifyAdminName: 'login',
    starterFaqFulfillmentNetwork: [
      'www.shopify.ca',
      'www.shopify.com',
    ].includes(site?.domain)
      ? ' and integrated fulfillment network'
      : '',
    ...urlInterpolations,
  };
}

export default interpolation;
