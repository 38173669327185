import {useMatches, UNSAFE_RemixContext} from '@remix-run/react';
import {createContext, useContext} from 'react';
import type {PropsWithChildren} from 'react';

import type {Handle} from '@/types';

const HandleContext = createContext({});

export function HandleProvider({
  handle,
  children,
}: PropsWithChildren<{handle: Handle}>) {
  return (
    <HandleContext.Provider value={handle}>{children}</HandleContext.Provider>
  );
}

export default function useHandle(): Handle {
  const handleContext = useContext(HandleContext);
  // Storybook is never rendered using Remix routes
  // @ts-expect-error: __IS_STORYBOOK__ is defined only in Storybook
  if (typeof __IS_STORYBOOK__ !== 'undefined' && __IS_STORYBOOK__) {
    return handleContext;
  }

  // Partials must load on the client outside of the Remix app
  // Unit tests need to be able to set handle data
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const remixContext = useContext(UNSAFE_RemixContext);
  if (!remixContext) {
    return handleContext;
  }

  // Remix app - the hooks will alway run in order because the above
  // conditions will never be true when inside RemixContext
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const matches = useMatches();
  let merged = {};
  for (const match of matches) {
    if (match.handle) {
      merged = {...merged, ...match.handle};
    }
  }
  return merged;
}
