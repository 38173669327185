import {useCallback} from 'react';

import {EXP_GMV_REWARDS_ACQUISITION_INCENTIVE} from '@/experiments';
import {useExperiment} from '@/hooks/useExperiment';
import {formatCurrency} from '@/utils/string/formatCurrency';
import {getFreeTrialUrlBySite} from '@/hooks/navigation/utils';
import {useSiteData} from '@/hooks/useSiteData';

import useIntlLocale from '../useIntlLocale';

const SIGNUP_TYPES_BY_EXPERIMENT: Record<string, string[]> = {
  treatment: ['gmv_rw_ywhk'],
  control: ['gmv_rw_myub'],
};

const getSignupTypes = (experiment: string | undefined): string[] => {
  if (!experiment) return [];

  return SIGNUP_TYPES_BY_EXPERIMENT[experiment] ?? [];
};

export const useGmvRewardsAcquisitionIncentive = () => {
  const experiment = useExperiment(EXP_GMV_REWARDS_ACQUISITION_INCENTIVE);
  const {site} = useSiteData();
  const isTreatment = 'treatment' === experiment;
  const signupTypes = getSignupTypes(experiment);
  const locale = useIntlLocale();

  const formatValue = useCallback(
    (value: number) => {
      return formatCurrency(locale, 'USD', value, {
        maximumFractionDigits: 0,
        currencyDisplay: 'symbol',
      });
    },
    [locale],
  );

  return {
    isTreatment,
    signupTypes,
    learnMore: getFreeTrialUrlBySite(site),
    formatValue,
    totalCap: isTreatment && formatValue(10000),
  };
};
