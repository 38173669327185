import env from '../env';
import {UrlParam} from '../../enums';

import type {SignupMetadata} from './types';

export const SIGNUP_BASE_URL = `${env(
  'SHOPIFY_IDENTITY_BASE_URI',
)}/store-create`;

export const DEFAULT_SIGNUP_METADATA: SignupMetadata = {
  url: SIGNUP_BASE_URL,
  email: undefined,
  pageViewToken: undefined,
};

export const AFFILIATE_TRACKING_PARAMS = [
  UrlParam.AffiliateIRClickId,
  UrlParam.AffiliateIRGWC,
  UrlParam.AffiliatePartnerId,
  UrlParam.AffiliatePartnerExcluded,
];

export const SIGNUP_REF_AND_PROMO_PARAMS = [
  UrlParam.SignupRef,
  UrlParam.PromoCode,
];
