import {
  isCookieFlagEnabled,
  isUrlFlagEnabled,
  isUrlOrCookieFlagEnabled,
} from './utils/clientCookie';
import env from './utils/env';
import {
  ApprovedCookie,
  CookieNoticeBannerVariant,
  ComplianceZone,
} from './enums';

export const DEFAULT_FAVICON = env('SITE_FAVICON');
export const baseUri = `https://${env('SITE_URL')}`;
export const DUX_ROUTE = '/__dux';

const getComplianceZone = (
  flag: DebugFlag,
  url: URL,
): ComplianceZone | undefined => {
  const complianceZone = url.searchParams.get(flag) as ComplianceZone;
  if (Object.values(ComplianceZone).includes(complianceZone))
    return complianceZone;
  else return undefined;
};

const getCookieNoticeBannerVariant = (
  flag: DebugFlag,
  url: URL,
): CookieNoticeBannerVariant | undefined => {
  const complianceZoneVariant = url.searchParams.get(
    flag,
  ) as CookieNoticeBannerVariant;
  if (Object.values(CookieNoticeBannerVariant).includes(complianceZoneVariant))
    return complianceZoneVariant;
  else return undefined;
};

enum DebugFlag {
  DebugDisableGTM = 'debug_disable_gtm',
  DebugRouterExp = 'debug_router_exp',
  DebugAuthMiddleware = 'debug_auth_middleware',
  DebugDisableAnalytics = 'debug_disable_analytics',
  DebugComplianceZone = 'debug_compliance_zone',
  DebugCookieNoticeBannerVariant = 'debug_compliance_zone_variant',
  DebugShopifyMerchant = 'debug_shopify_merchant',
}

export const FEATURE_FLAGS = {
  // force-enable gtm for testing
  DEBUG_DISABLE_GTM: isUrlFlagEnabled(DebugFlag.DebugDisableGTM),
  // Used to troubleshoot and investigate issues with gtag/analytics. ?debug_disable_analytics=true` to use
  DEBUG_DISABLE_ANALYTICS: isUrlFlagEnabled(DebugFlag.DebugDisableAnalytics),
  // Used to force logged_in cookie to "true" for testing
  DEBUG_SHOPIFY_MERCHANT: isUrlFlagEnabled(DebugFlag.DebugShopifyMerchant),
  // Used to enable GDPR compliance zone for debugging
  DEBUG_COMPLIANCE_ZONE: (url: URL) =>
    getComplianceZone(DebugFlag.DebugComplianceZone, url),
  // Used to enable GDPR compliance zone for debugging - new UI
  DEBUG_COMPLIANCE_ZONE_VARIANT: (url: URL) =>
    getCookieNoticeBannerVariant(DebugFlag.DebugCookieNoticeBannerVariant, url),
  // Used to validate auth middleware across environments `?debug_auth_middleware=true`
  DEBUG_AUTH_MIDDLEWARE: (url: URL) =>
    isUrlFlagEnabled(DebugFlag.DebugAuthMiddleware, url),
  // Used to add additional logging for routes / experiment testing
  DEBUG_ROUTER_EXP: (url?: URL) =>
    isUrlFlagEnabled(DebugFlag.DebugRouterExp, url),

  ENABLE_REDESIGN: (url?: URL, cookies?: {[key: string]: string}) =>
    isUrlOrCookieFlagEnabled(ApprovedCookie.EnableRedesign, url, cookies),

  DISABLE_DEBUG_BAR: (url?: URL, cookies?: {[key: string]: string}) =>
    isUrlOrCookieFlagEnabled(ApprovedCookie.DisableDebugBar, url, cookies),

  ENABLE_DEVMODE: (cookies?: {[key: string]: string}) =>
    isCookieFlagEnabled(ApprovedCookie.EnableDevmode, cookies),
};

export const VIDEO_MODAL_URL_HASH = new RegExp(/^[/#]*video+(?:\/(\w+))/);
export const PRIVACY_PREFERENCES_MODAL_URL_HASH_KEY = '#privacy';
export const PRIVACY_PREFERENCES_MODAL_URL_COMPONENT_NAME = 'manage-privacy';

export const BNG_REQUEST_SESSION_KEY = 'BNGRequests';

export const SHOULD_LOG = env('APP_ENV') !== 'production';

export const START_FREE_TRIAL_NAME = 'start-free-trial';
export const PLUS_GET_IN_TOUCH = 'plus-get-in-touch';

export const GTM_ID_DEFAULT = 'GTM-TZ26LP8';
// /plus/* pages use a different GTM Account
export const GTM_ID_PLUS = 'GTM-T8J95ZG';

export const GOOGLE_MAPS_API_KEY = 'AIzaSyC8QIE4fxuIM6tus4gHTAon27pJAOJwFU8';
