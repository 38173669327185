import useIntlCurrency from '@/hooks/useIntlCurrency';
import useIntlLocale from '@/hooks/useIntlLocale';

import type {Site} from '../server/Site';

export function useFormatCurrency() {
  const locale = useIntlLocale();
  const currency = useIntlCurrency();
  return (value = 0, options: Intl.NumberFormatOptions = {}) =>
    formatCurrency(locale, currency, value, options);
}

export const formatCurrency = (
  locale = 'en-US',
  currency = 'USD',
  value = 0,
  options = {},
): string => {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currencyDisplay: 'narrowSymbol',
    currency,
    ...options,
  }).format(value);
};

export const formatCurrencyToParts = (
  locale = 'en-US',
  currency = 'USD',
  value = 0,
): {
  currencySymbol: string;
  integer: string;
  decimal: string;
  fraction: string;
} => {
  const formattedPrice = new Intl.NumberFormat(locale, {
    style: 'currency',
    currencyDisplay: 'narrowSymbol',
    currency,
  }).formatToParts(value);

  // These can come back as a series of parts for the main value number (not decimals)
  // e.g. [{type: 'integer', value: '10'}, {type: 'group', value: ','}, {type: 'integer', value: '407'}]
  // so composing them together into a string, to include in the result parts:
  const mainIntValueComposed = formattedPrice
    .filter((part) => part.type === 'integer' || part.type === 'group')
    .reduce((acc, part) => acc + part.value, '');

  // .formatToParts returns an array of objects with the following properties:
  // [ {type: 'currency', value: '$'},
  //   {type: 'integer', value: '1'},
  //   {type: 'decimal', value: '.'},
  //   {type: 'fraction', value: '56'} ]
  // so the follow reducer turns it into a single object:
  // {currency: '$', integer: '1', decimal: '.', fraction: '56'}
  const parts = formattedPrice
    ?.filter((part) => part.type !== 'integer' && part.type !== 'group')
    .reduce((obj: any, item: any) => {
      return {
        ...obj,
        integer: mainIntValueComposed,
        // change the key from currency to currencySymbol,
        // since currency means USD in this case, and what we get back
        // is a symbol for the currency like $, €, etc.
        [item.type === 'currency' ? 'currencySymbol' : item.type]: item.value,
      };
    }, {});

  return parts;
};

export const useCurrencyFormatterGen = () => {
  const locale = useIntlLocale();
  const currency = useIntlCurrency();
  return (value: number, options?: object): string =>
    formatCurrency(locale, currency, value, options || {});
};

export const isCurrencyFractional = (site: Site) => {
  return Intl.NumberFormat(site.locale, {
    style: 'currency',
    currency: site.currencyCode,
  })
    .formatToParts(1)
    .some(({type}) => type === 'decimal');
};
