import isEmpty from 'lodash/isEmpty';

import type {Site} from '@data/types';

export type LocaleDataProp = {[locale: string]: any};

export const localeData = <T>(data: LocaleDataProp, site: Site): T => {
  try {
    let stringObject = data[site.locale];
    if ((!stringObject || isEmpty(stringObject)) && site.locale.includes('-')) {
      const [locale] = site.locale.split('-');
      stringObject = data[locale];
    }
    if ((!stringObject || isEmpty(stringObject)) && site.pathPrefix) {
      stringObject = data.intl;
    }
    if (!stringObject || isEmpty(stringObject)) {
      stringObject = data.en;
    }
    return stringObject as T;
  } catch (e) {}

  return null as unknown as T;
};
