import {useEffect, useState} from 'react';

export const useGuidanceSignupType = () => {
  const SIGNUP_TYPE_PREFIX = 'marketplace_and_social_media_guidance';

  const handlesWithSignupTypes: {[key: string]: string} = {
    '/blog/pinterest-marketing': 'social_media',
    '/blog/sell-on-amazon': 'online_marketplace',
    '/start?signup_type=marketplace_and_social_media_guidance__social_media':
      'social_media',
  };

  const [url, setUrl] = useState('');

  useEffect(() => {
    setUrl(window.location.href);
  }, [url]);

  const firstMatchingHandleInUrl = Object.keys(handlesWithSignupTypes).find(
    (key) => url.includes(key),
  ) as string;

  if (firstMatchingHandleInUrl) {
    return {
      signupType: [
        `${SIGNUP_TYPE_PREFIX}__${handlesWithSignupTypes[firstMatchingHandleInUrl]}`,
      ],
    };
  }

  return {};
};
