import {createContext, useContext} from 'react';

import type {Site, Region} from '@data/types';
import {UrlUtils} from '@/utils/UrlUtils';

export interface SiteDataContextValue {
  site: Site;
  experimentVariationId?: string;
  domain?: string;
  regions?: Region[];
  realCountryCode?: string;
  geoCountryCode?: string;
  regionCode?: string;
  enPath?: string;
  fileRoutePath?: string; // represents where the page data is stored in the filesystem relative to src/pages
  canonicalUrl?: string;
  handle?: string;
  editMode?: boolean;
  /**
   * Get a URL for a Shopify app or path.
   *
   * This convenience method is from an instance of `UrlUtils` set on the
   * `SiteDataContext` to avoid having to import and create an instance of the
   * `UrlUtils` class every time you need to call `getUrl`.
   *
   * @see src/utils/UrlUtils.ts
   */
  getUrl: UrlUtils['getUrl'];
}

export const extractSiteData = (data: any) => {
  const {
    site = {},
    experimentVariationId,
    domain,
    regions = [],
    metadata,
    realCountryCode,
    geoCountryCode,
    regionCode,
    enPath,
    fileRoutePath,
    canonicalUrl,
    handle,
    editMode,
  } = data || {};
  const urlUtils = new UrlUtils(site);

  return {
    site: site || {},
    experimentVariationId,
    domain,
    regions,
    metadata,
    realCountryCode,
    geoCountryCode,
    regionCode,
    enPath,
    fileRoutePath,
    canonicalUrl,
    handle,
    editMode,
    getUrl: urlUtils.getUrl.bind(urlUtils),
  };
};

export const SiteDataContext = createContext<SiteDataContextValue | null>(null);

export function useSiteData(): SiteDataContextValue {
  const context = useContext(SiteDataContext);

  if (context === null) {
    throw new Error('No Site Data Context available');
  }

  return context;
}
