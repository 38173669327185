import {useEffect, useState} from 'react';

const SIGNUP_TYPE_PREFIX = 'replatformer';

const competitors = [
  'bluehost',
  'clover',
  'salesforce-commercecloud',
  'elementor',
  'etsy',
  'godaddy',
  'hostgator',
  'lightspeed',
  'poshmark',
  'quickbooks',
  'square',
  'squarespace',
  'vend',
  'webflow',
  'wix',
  'woocommerce',
  'wordpress',
];

export const useReplatformerSignupType = () => {
  const [url, setUrl] = useState('');

  useEffect(() => {
    setUrl(window.location.href);
  }, []);

  const competitorHandleInUrl = competitors.find((competitor) =>
    url.includes(competitor),
  );

  const multipleCompetitorHandlesInUrl = url.includes('-vs-shopify');

  if (multipleCompetitorHandlesInUrl) {
    return {
      signupType: [`${SIGNUP_TYPE_PREFIX}`],
    };
  } else if (competitorHandleInUrl) {
    return {
      signupType: [`${SIGNUP_TYPE_PREFIX}__${competitorHandleInUrl}`],
    };
  }

  return {};
};
