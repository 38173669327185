import {usePricingData} from '@/hooks/usePricingData';
import {useSiteData} from '@/hooks/useSiteData';

export default function useIntlLocale(usePricing = false) {
  const {site} = useSiteData();
  const {pricingSite} = usePricingData();
  const s = usePricing && pricingSite ? pricingSite : site;
  const languageCode = s.languageCode || s.locale?.split('-')[0] || 'en';
  if (languageCode.endsWith(s.countryCode)) {
    return languageCode;
  }
  return `${languageCode}-${s.countryCode}`;
}
