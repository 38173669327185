import env from '../../utils/env';

const scheme = 'https://';
const domainTLD = 'shopify.com';

/**
 * @todo Move these constants into `src/UrlUtils.ts`, though maybe they should
 * be in a constants file to avoid circular dependencies.
 */

/**
 * @deprecated Please do not use this directly, use `UrlUtils.getUrl()` instead
 */
export const academyhost = `${scheme}academy.${domainTLD}`;
/**
 * @deprecated Please do not use this directly, use `UrlUtils.getUrl()` instead
 */
export const appHost = `${scheme}apps.${domainTLD}`;
/**
 * @deprecated Please do not use this directly, use `UrlUtils.getUrl()` instead
 */
export const partnersHost = `${scheme}partners.${domainTLD}`;
/**
 * @deprecated Please do not use this directly, use `UrlUtils.getUrl()` instead
 */
export const changelogHost = `${scheme}changelog.${domainTLD}`;
/**
 * @deprecated Please do not use this directly, use `UrlUtils.getUrl()` instead
 */
export const shopifyAdminHost = `${env('SHOPIFY_ADMIN_BASE_URI')}`;
/**
 * @deprecated Please do not use this directly, use `UrlUtils.getUrl()` instead
 */
export const shopifyDevHost = `${scheme}shopify.dev`;
/**
 * @deprecated Please do not use this directly, use `UrlUtils.getUrl()` instead
 */
export const themesHost = `${scheme}themes.${domainTLD}`;
/**
 * @deprecated Please do not use this directly, use `UrlUtils.getUrl()` instead
 */
export const helpHost = `${scheme}help.${domainTLD}`;
/**
 * @deprecated Please do not use this directly, use `UrlUtils.getUrl()` instead
 */
export const investorsHost = `${scheme}investors.${domainTLD}`;
/**
 * @deprecated Please do not use this directly, use `UrlUtils.getUrl()` instead
 */
export const privacyHost = `${scheme}privacy.${domainTLD}`;
