import sitesJson from '../../../data/sites.json';
import type {Site as SiteType} from '../../../data/types';

const SITES = sitesJson as SiteType[];

export const PATH_PREFIXES = SITES.map((site) => site.pathPrefix).filter(
  (pathPrefix) => Boolean(pathPrefix),
);

export const PATH_PREFIX_UNION = PATH_PREFIXES.join('|');

export const PATH_PREFIXES_REGEXP = new RegExp(`^/(${PATH_PREFIX_UNION})(/|$)`);

export class Site implements SiteType {
  /**
   * @deprecated please use pathPrefix or locale instead
   */
  readonly domain: string;
  readonly countryCode: string;
  readonly hreflang: string;
  readonly locale: string;
  readonly baseEnDomain?: boolean;
  readonly currencyCode: string;
  readonly features: string[];
  readonly pathPrefix?: string;
  readonly languageCode: string;

  constructor({
    domain,
    countryCode,
    hreflang,
    locale,
    baseEnDomain,
    currencyCode,
    features,
    pathPrefix,
    languageCode,
  }: SiteType) {
    this.domain = domain;
    this.countryCode = countryCode;
    this.hreflang = hreflang;
    this.locale = locale;
    this.baseEnDomain = baseEnDomain;
    this.currencyCode = currencyCode;
    this.features = features;
    this.pathPrefix = pathPrefix;
    this.languageCode = languageCode;
  }

  // get fallbackLocale() {
  //   // TODO: we should let sites specify their fallback locale so
  //   // we can handle scenarios like fr-CA -> en-CA, instead of fr-CA -> fr
  //   return this.locale.includes('-') ? this.locale.split('-')[0] : this.locale;
  // }

  static getPathPrefix(path: string): string | undefined {
    return path.match(PATH_PREFIXES_REGEXP)?.[1] || undefined;
  }

  static fromPath(path: string): Site | undefined {
    path = path.startsWith('/') ? path : `/${path}`;
    const pathPrefix = Site.getPathPrefix(path);
    const selectedSites = SITES;

    const site = selectedSites.find((s) => {
      return s.pathPrefix === (pathPrefix || undefined);
    });

    return site ? new Site(site) : undefined;
  }

  /**
   * Returns the first site that matches the given country code.
   * This method should not be used for locale matching since a country
   * can have multiple locales.
   * @param countryCode
   */
  static fromCountry(countryCode: string): Site | undefined {
    const selectedSites = SITES;

    const site = selectedSites.find((s) => s.countryCode === countryCode);
    return site ? new Site(site) : undefined;
  }

  static fromLocale(locale: string): Site | undefined {
    const selectedSites = SITES;

    const site = selectedSites.find((s) => s.locale === locale);
    return site ? new Site(site) : undefined;
  }

  /**
   *  Returns a site from a domain
   *
   *  @param domain the domain to find a site for
   *  @deprecated please use fromPathPrefix
   */
  static fromDomain(domain: string): Site | undefined {
    const selectedSites = SITES;

    const site = selectedSites.find((s) => s.domain === domain);
    if (!site) {
      return;
    }
    return new Site(site);
  }

  static getLocalePathPrefixMap(): Record<string, string> {
    const localePathPrefixMap: Record<string, string> = {};
    SITES.forEach((site) => {
      if (site.locale && site.pathPrefix) {
        localePathPrefixMap[site.locale] = site.pathPrefix;
      }
    });
    return localePathPrefixMap;
  }
}

export const DEFAULT_SITE = Site.fromPath('/')!;
