import {EXP_INTERNATIONAL_ACQUISITION_INCENTIVE} from '@/experiments';
import {useExperiment} from '@/hooks/useExperiment';
import {useSiteData} from '@/hooks/useSiteData';
import {formatCurrency} from '@/utils/string/formatCurrency';
import {getFreeTrialUrlBySite} from '@/hooks/navigation/utils';

const ELIGIBLE_COUNTRIES = ['JP', 'FR', 'DE', 'IT'];

export const isEligibleForInternationalRedirect = (
  assignment: string | undefined,
  realCountryCode: string | undefined,
  pathPrefix: string | undefined,
) => {
  return (
    assignment === 'treatment' &&
    ELIGIBLE_COUNTRIES.includes(realCountryCode || '') &&
    pathPrefix?.toLocaleLowerCase() !== realCountryCode?.toLocaleLowerCase()
  );
};

const getGuideLink = (
  realCountryCode: string | undefined,
  languageCode: string | undefined,
) => {
  switch (realCountryCode) {
    case 'JP':
      return `https://apps.shopify.com/stories/guide-built-for-japan?locale=${languageCode}`;
    case 'FR':
      return `https://apps.shopify.com/stories/guide-built-for-france?locale=${languageCode}`;
    case 'DE':
      return `https://apps.shopify.com/stories/guide-built-for-germany?locale=${languageCode}`;
    case 'IT':
      return `https://apps.shopify.com/stories/guide-built-for-italy?locale=${languageCode}`;

    default:
      return `https://apps.shopify.com?locale=${languageCode}`;
  }
};

export const useIntlAcquisitionIncentive = () => {
  const {realCountryCode, site} = useSiteData();
  const amount =
    realCountryCode === 'JP'
      ? formatCurrency('ja-JP', 'JPY', 15000, {
          maximumFractionDigits: 0,
        })
      : formatCurrency('de-DE', 'EUR', 100, {
          maximumFractionDigits: 0,
        });

  const active =
    'treatment' === useExperiment(EXP_INTERNATIONAL_ACQUISITION_INCENTIVE) &&
    ELIGIBLE_COUNTRIES.includes(realCountryCode || '');

  const guideLink = getGuideLink(realCountryCode, site?.languageCode);
  return {
    active,
    signupTypes: active ? ['intl_acq_mtyv'] : [],
    learnMore: getFreeTrialUrlBySite(site),
    creditAmount: amount,
    guideLink,
  };
};
