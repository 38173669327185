import type {i18n} from 'i18next';

import type {Site} from '../data/types';

import type {AppKeysUnion} from './utils/UrlUtils';
import {appKeyMap, UrlUtils} from './utils/UrlUtils';
import env from './utils/env';
import supportedLngs from './i18n.locales';

export const namespaces = ['global', 'footer', 'nav', 'pricing'];

export const pageNamespaces = (path: string) => {
  const nsPath = path === '/' ? '' : path;
  return [`pages${nsPath}/content`, `pages${nsPath}/settings`];
};

export const loadNamespaces = (loaded: string[], path: string) => {
  const ns = new Set(loaded);
  if (!path) {
    return [...ns];
  }

  pageNamespaces(path).forEach((pageHandle) => {
    !ns.has(pageHandle) && ns.add(pageHandle);
  });

  return [...ns];
};

type UrlFormatterOptions = {
  path: string;
  interpolationkey: AppKeysUnion;
};

export const urlFormatterErrorMsg = {
  start: 'Invalid `interpolationkey`: `',
  end: '` used in i18n `url()` custom formatter',
};

export const formatters = (instance: i18n, _site: Site) => {
  instance.services.formatter?.add(
    'url',
    (_value, _lang, {path, interpolationkey}: UrlFormatterOptions) => {
      const notFound = !appKeyMap[interpolationkey];

      /**
       * Throw an exception for non-prod envs when an invalid `interpolationkey`
       * is passed to `url()` for tophatting purposes
       */
      if (env('SHOPIFY_APP_ENV') !== 'production' && notFound) {
        throw new Error(
          `${urlFormatterErrorMsg.start}${interpolationkey}${urlFormatterErrorMsg.end}`,
        );
      }

      /**
       * For production, fail silently
       */
      if (notFound) {
        return '';
      }

      const urlUtils = new UrlUtils(_site);

      return urlUtils.getUrl(path, appKeyMap[interpolationkey]);
    },
  );
};

export default (locale?: string) => ({
  // This is the list of languages your application supports
  supportedLngs,
  // This is the language you want to use in case
  // if the user language is not in the supportedLngs
  preload: locale ? [locale, 'en'] : ['en'],
  fallbackLng: ['en'],
  // The default namespace of i18next is "translation", but you can customize it here
  defaultNS: 'global',
  // Disabling suspense is recommended
  react: {useSuspense: false},

  // allows us to access arrays and get back the translated values
  returnObjects: true,
  parseMissingKeyHandler: () => {
    // If the key is not found, return empty string instead of the key itself
    return '';
  },

  interpolation: {
    escapeValue: false,
  },
});
