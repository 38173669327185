import type {Site} from '@data/types';

import {localeData} from './data';

type ImageProps = {
  [locale: string]: string;
};

type IconProps = {
  [locale: string]: {icon: string}[];
};

export type LocaleImageData = ImageProps | IconProps;

export type Image = string;
type Icons = {icon: string}[];
export type Images = {image: string}[];

type Media = {[key: string]: string};

export const localeImage = <T extends Icons | Image | Image[] | Images | Media>(
  data: LocaleImageData,
  site: Site,
): T => {
  return localeData(data, site) as T;
};
