import {
  shopifyAdminHost,
  shopifyDevHost,
  themesHost,
  changelogHost,
  privacyHost,
  partnersHost,
  academyhost,
} from '../hooks/navigation/constants';
import type {Site} from '../../data/types';
import {
  appLocalizedUrl,
  blogPath,
  hardwareUrl,
  helpUrl,
} from '../hooks/navigation/utils';

import {localizePathBySite} from './site';

/**
 * The keys of Shopify apps, paths we want to link to.
 *
 * This is the source of truth, if you need to add a new app key, add it here.
 *
 * @note One way these enum's keys are used is to spread them into the
 * `interpolation()` function's return object in `i18n.interpolations.ts` so
 * any changes here are reflected for `url()` interpolation keys.
 *
 * @note Please do not use this map to pass a `key` to `getUrl()`, just use a
 * string to match the `AppKeysUnion` type.
 */
export const appKeyMap = {
  admin: 'admin',
  app: 'app', // @todo Change to `appStore`
  blog: 'blog',
  changelog: 'changelog',
  dev: 'dev',
  hardware: 'hardware', // @todo Change to `hardwareStore`
  help: 'help',
  partners: 'partners',
  privacy: 'privacy',
  site: 'site', // @todo Change to `brochure`
  themes: 'themes',
  academy: 'academy',
} as const;

export type AppKeysUnion = keyof typeof appKeyMap;

/**
 * Convenience array of app keys for iteration. Relies on `appKeyMap` key === val
 */
export const appKeys = Object.values(appKeyMap);

/**
 * Util class to house URL methods. Create an instance with `site` to give
 * instance methods (e.g. `yourInstance.getUrl()`)  access to `site` data.
 *
 * @example
 *
 * const urlUtils = new UrlUtils(site);
 *
 * @todo Move `hooks/navigation` utils into this class
 */
export class UrlUtils {
  private site: Site;

  constructor(site: Site) {
    this.site = site;
  }

  /**
   * Get a URL for a Shopify app or path, defaults to b3 paths.
   *
   * @example
   *
   * const instance = new UrlUtils(site);
   *
   * // `https://shopify.com`
   * instance.getUrl('/')
   * // `https://shopify.com/foo`
   * instance.getUrl('/foo', 'site')
   * // `https://admin.shopify.com`
   * instance.getUrl('/', 'admin)
   * // `https://admin.shopify.com?query=param`
   * instance.getUrl('/?query=param', 'admin')
   *
   * // Also available from:
   *
   * const {getUrl} = useSiteData();
   * const href = getUrl('/');
   *
   * @todo Look into creating a union or enum type for `locale`
   * @todo Integrate `locale` param after debugging `pathPrefix` requirements
   *       for `localizedPath()` called by `localizePathBySite()`
   */
  getUrl(path: string, key?: AppKeysUnion) {
    const normalizedPath = path === '/' ? '' : path;
    let host = '';

    switch (key) {
      case undefined:
      case appKeyMap.site: // @todo Change to `brochure`
        return localizePathBySite(normalizedPath, this.site);
      case appKeyMap.help:
        return helpUrl(this.site, normalizedPath);
      case appKeyMap.blog:
        return blogPath(normalizedPath, this.site);
      case appKeyMap.hardware: // @todo Change to `hardwareStore`
        return `${hardwareUrl(this.site)}${normalizedPath}`;
      case appKeyMap.app: // @todo Change to `appStore`
        return appLocalizedUrl(this.site, normalizedPath);
      case appKeyMap.admin:
        host = shopifyAdminHost;
        break;
      case appKeyMap.dev:
        host = shopifyDevHost;
        break;
      /**
       * @todo Integrate `themesUrl`, not doing it just yet because it doesn't
       * accept a `path` param and needs some refactoring.
       */
      case appKeyMap.themes:
        host = themesHost;
        break;
      case appKeyMap.changelog:
        host = changelogHost;
        break;
      case appKeyMap.privacy:
        host = `${privacyHost}${
          this.site.locale?.startsWith('fr') ? '/fr' : '/en'
        }`;
        break;
      case appKeyMap.partners:
        host = partnersHost;
        break;
      case appKeyMap.academy:
        host = academyhost;
        break;

      default:
        host = '';
        break;
    }

    return `${host}${normalizedPath}`;
  }

  /**
   * @todo Integrate into `getUrl()`
   */
  // @ts-expect-error This will be integrated in a future PR
  private _mergeLocale(localeOverride?: Site['locale']) {
    const {locale, ...siteRest} = this.site;

    return {
      ...siteRest,
      locale: localeOverride ?? locale,
    };
  }

  /**
   * Convenience function to create an object from an array of keys and assign
   * each key a value with an optional callback.
   */
  static _keysToObj(keys: typeof appKeys, cb: (key: AppKeysUnion) => string) {
    return keys.reduce<{[k: string]: string}>((result, key) => {
      result[key] = cb(key);
      return result;
    }, {});
  }
}
