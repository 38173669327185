import {
  createContext,
  useContext,
  useMemo,
  type PropsWithChildren,
} from 'react';
import {useTranslation} from 'react-i18next';
import type {TFunctionReturn} from 'i18next';

import type {Site} from '@data/types';
import interpolation from '@/i18n.interpolation';
import {localizePathBySite} from '@/utils/site';
import useHandle from '@/hooks/useHandle';
import {signupUrl} from '@/hooks/navigation/utils';
import {usePaidTrialLengthPromotion} from '@/hooks/experiments/usePaidTrialLengthPromotion';
import {localeImage, type LocaleImageData} from '@/utils/image';
import {localeData, type LocaleDataProp} from '@/utils/data';
import {pageNamespaces} from '@/i18n';
import {useGuidanceSignupType} from '@/hooks/experiments/useGuidanceSignupType';
import {useReplatformerSignupType} from '@/hooks/useReplatformerSignupType';
import {useIntlAcquisitionIncentive} from '@/hooks/experiments/useIntlAcquisitionIncentive';
import {useGmvRewardsAcquisitionIncentive} from '@/hooks/experiments/useGmvRewardsAcquisitionIncentive';
import {useRetailSignupType} from '@/hooks/experiments/useRetailSignupType';

import {useSiteData} from './SiteData';
import {usePricingData} from './PricingData';

const I18NContext = createContext({} as TranslationsHook);

interface I18NProps {
  experimentVariationId?: string;
  canonicalUrl?: string;
  enPath?: string;
}

export type HydratedT = (key: string | string[], options?: any) => any;

export interface TranslationsHook {
  t: HydratedT;
  site: Site;
  /** @deprecated - see https://brochure-iii.docs.shopify.io/pages/data for fetching non-translated data */
  settings: HydratedT;
  localizePath: (path: string) => string;
  images: any;
  localeImage: <T>(data: LocaleImageData) => T;
  localeData: <T>(data: LocaleDataProp) => T;
  values: any;
}

export const useTranslations = (): TranslationsHook => useContext(I18NContext);

export default function I18N({
  canonicalUrl,
  children,
}: PropsWithChildren<I18NProps>) {
  const {site, fileRoutePath} = useSiteData();
  const pricingData = usePricingData();
  const handle = useHandle();

  const [contentNs, settingsNs] = fileRoutePath
    ? pageNamespaces(fileRoutePath)
    : [];

  const {t: translate, ...rest} = useTranslation(
    contentNs && settingsNs ? [contentNs, settingsNs] : 'global',
  );
  const siteValue = useMemo(() => site || ({} as Site), [site]);

  const {
    experimentOverrideKeys: paidTrialOverrideKeys,
    signupTypes: paidTrialSignupTypes = [],
  } = usePaidTrialLengthPromotion();
  const {signupType: retailSignupType = []} = useRetailSignupType();
  const {signupType: guidanceSignupType = []} = useGuidanceSignupType();
  const {signupType: replatformerSignupType = []} = useReplatformerSignupType();
  const {signupTypes: intlAcqIncentiveSignupType = []} =
    useIntlAcquisitionIncentive();
  const {signupTypes: gmvRewardsAcquisitionIncentiveSignupType} =
    useGmvRewardsAcquisitionIncentive();

  const values: any = useMemo(
    () => interpolation(siteValue, pricingData),
    [siteValue, pricingData],
  );

  // @ts-ignore
  const t = (key, options = {}): TFunctionReturn =>
    translate(key, {
      ...values,
      defaultValue: '',
      lng: site?.locale,
      ...options,
    });

  // TODO: Use a hook for this instead of using an interpolation
  // Pass it in to the places that use deeplinkFreeTrial the same way
  // we pass in displayName to t for plans
  const deeplinkSignupTypes = [
    ...paidTrialSignupTypes,
    ...guidanceSignupType,
    ...replatformerSignupType,
    ...intlAcqIncentiveSignupType,
    ...gmvRewardsAcquisitionIncentiveSignupType,
    ...retailSignupType,
  ];
  values.deeplinkFreeTrial = site
    ? signupUrl(
        site as Site,
        canonicalUrl as string,
        handle,
        deeplinkSignupTypes,
      )
    : '';

  const settings = (key: string | string[], options = {}) =>
    t(key, {...options, ns: settingsNs});

  const images = settings('images', {lng: 'en'}) || {};

  paidTrialOverrideKeys?.forEach((override) => {
    values[override.key] = override.value;
  });

  const localizePath = (path: string) => localizePathBySite(path, site as Site);
  const value: TranslationsHook = {
    ...rest,
    t,
    settings,
    site: siteValue,
    localizePath,
    images,
    localeImage<T>(data: LocaleImageData) {
      return localeImage(data, siteValue) as T;
    },
    localeData<T>(data: LocaleDataProp) {
      return localeData(data, siteValue) as T;
    },
    values,
  };

  // returns a render prop so the Wrapper component can immediately use value
  return <I18NContext.Provider value={value}>{children}</I18NContext.Provider>;
}
