import {createContext, useContext} from 'react';

import type {PricingDataContextValue} from '@data/types';

export const extractPricingData = (data: any): PricingDataContextValue => {
  const {
    pricingSite,
    carriers = [],
    pricing,
    geoPricingIsEnabled = false,
  } = data || {};

  return {
    pricingSite,
    carriers,
    pricing,
    geoPricingIsEnabled,
  };
};

export const PricingDataContext = createContext<PricingDataContextValue>({});

export function usePricingData(): PricingDataContextValue {
  return useContext(PricingDataContext);
}
