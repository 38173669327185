import {matchRoutes} from '@remix-run/router';

import pagesJson from '../../../data/pages.json';
import type {
  LanguageCode,
  PageConfig,
  Pages,
  Translations,
} from '../../../data/types';
import {removePathPrefix} from '../paths';
import env from '../env';
import {safelyDecodeURIComponent} from '../string/safeDecodeUrl';

import {DEFAULT_SITE, PATH_PREFIXES_REGEXP, Site} from './Site';

const showDraft = env('PAGE_VISIBILITY') === 'draft';

const records = pagesJson as Pages;

type PageProps = PageConfig & {
  routePath: string;
  enPath: string;
  i18nNamespaces?: string[];
  params?: Record<string, string[]>;
};

const remixPaths = Object.keys(records).map((key) => ({
  path: key,
}));

const translatedPaths = Object.entries(records).flatMap(([enPath, config]) => {
  const {translations} = config;
  if (!translations) {
    return [];
  }

  return Object.entries(translations).map(([languageCode, translatedPath]) => {
    return {
      languageCode,
      enPath,
      translatedPath,
    };
  });
});

export const getEnPath = (
  cleanedPath: string,
  languageCode: string,
  translatedPathsArray: {
    languageCode: string;
    enPath: string;
    translatedPath: string;
  }[],
  pathPrefix?: string,
) => {
  let enPath = cleanedPath;
  if (pathPrefix) {
    enPath =
      translatedPathsArray.find((p) => {
        // Split both paths into segments
        const translatedSegments = p.translatedPath.split('/');
        const cleanedSegments = cleanedPath.split('/');

        // If the paths have different numbers of segments, they don't match
        if (translatedSegments.length !== cleanedSegments.length) return false;

        // Compare each segment
        for (let i = 0; i < translatedSegments.length; i++) {
          const translatedSegment = translatedSegments[i];
          const cleanedSegment = cleanedSegments[i];

          // If the translated segment starts with ':', it's a dynamic segment and matches anything
          // Otherwise, the segments must be exactly equal
          if (
            !(
              translatedSegment.startsWith(':') ||
              translatedSegment === cleanedSegment
            )
          )
            return false;
        }

        // If all segments matched and the language codes match, it's a match
        return p.languageCode === languageCode;
      })?.enPath || cleanedPath;
  }

  return enPath;
};

const EXPERIMENTAL_LOCALES = ['tr', 'cs', 'pl', 'pt-PT', 'fi', 'th', 'nb'];

export class Page {
  public readonly handle: string;
  public readonly enPath: string;
  public readonly i18nNamespaces?: string[];
  public readonly routeFilePath: string;
  public readonly params?: Record<string, string[]>;
  /*
   * routePath is the path registered in data/pages.json. Pages with dynamic segments can
   * be registered with the dynamic segment or the exact paths if the paths are known.
   */
  public readonly routePath: string;
  public readonly translations?: Translations;
  private readonly publishedLocales?: 'ALL' | string[];
  private readonly draftLocales?: 'ALL' | string[];

  constructor({
    handle,
    enPath,
    publishedLocales,
    draftLocales,
    i18nNamespaces,
    routePath,
    translations,
    routeFilePath,
    params,
  }: PageProps) {
    this.handle = handle;
    this.publishedLocales = publishedLocales;
    this.draftLocales = draftLocales;
    this.enPath = enPath;
    this.i18nNamespaces = i18nNamespaces;
    this.routePath = routePath;
    this.translations = translations;
    this.routeFilePath = routeFilePath;
    this.params = params;
  }

  isPublishedInAnyLocale() {
    if (this.publishedLocales && this.publishedLocales.length !== 0) {
      return true;
    }

    return showDraft && this.draftLocales && this.draftLocales.length !== 0;
  }

  isPublishedOnSite(site: Site) {
    const {locale} = site;
    if (this.publishedLocales) {
      if (
        this.publishedLocales === 'ALL' &&
        !EXPERIMENTAL_LOCALES.includes(locale)
      ) {
        return true;
      }

      if (this.publishedLocales.includes(locale)) {
        return true;
      }
    }

    return showDraft && this.isDraftOnSite(site);
  }

  private isDraftOnSite(site: Site) {
    if (this.draftLocales === 'ALL') {
      return true;
    }

    return !!this.draftLocales?.includes(site.locale);
  }

  localizedPath(site: Site) {
    const {pathPrefix, languageCode} = site;
    if (!pathPrefix) {
      return this.enPath;
    }

    if (this.enPath === '/') {
      return `/${pathPrefix}`;
    }

    let translatedPath = this.translations?.[languageCode as LanguageCode];

    if (!translatedPath) {
      return `/${pathPrefix}${this.enPath}`;
    }

    // replace dynamic segments with the actual values
    translatedPath = translatedPath
      .split('/')
      .map((segment, index) => {
        if (segment.startsWith(':')) {
          return this.enPath.split('/')[index];
        }

        return segment;
      })
      .join('/');

    return `/${pathPrefix}${translatedPath}`;
  }

  static byPath(path: string): Page | undefined {
    path = safelyDecodeURIComponent(path);
    path = path.startsWith('/') ? path : `/${path}`;
    const pathPrefix = path.match(PATH_PREFIXES_REGEXP)?.[1] || undefined;
    const cleanedPath = pathPrefix ? removePathPrefix(path, pathPrefix) : path;
    const {languageCode} = Site.fromPath(path) || DEFAULT_SITE;

    let enPath = getEnPath(
      cleanedPath,
      languageCode,
      translatedPaths,
      pathPrefix,
    );

    const matches = matchRoutes(remixPaths, enPath);
    enPath = matches?.length ? matches![0].route.path : path;
    const recordPath = enPath;

    const dynamicSegmentCount = enPath.split('/:').length - 1;
    for (let i = 0; i < dynamicSegmentCount; i += 1) {
      const parts = enPath.split('/');
      const indexOfParam = parts.findIndex((part) => part.startsWith(':'));
      if (indexOfParam === -1) continue;
      parts[indexOfParam] = cleanedPath.split('/')[indexOfParam];
      enPath = parts.join('/');
    }

    if (enPath.endsWith('*')) {
      const parts = enPath.split('/');
      const indexOfParam = parts.length - 1;
      const combinedPath = [
        ...parts.slice(0, indexOfParam),
        ...cleanedPath.split('/').slice(indexOfParam),
      ];
      enPath = combinedPath.join('/');
    }

    const page = (records as any)[recordPath];

    if (!page) {
      return;
    }

    const routePath = recordPath.replace(/\/:/g, '/$').replace('/*', '/$');

    return new Page({...page, routePath, enPath});
  }
}
