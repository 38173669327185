export const enum SameSite {
  Lax = 'Lax',
  Strict = 'Strict',
  None = 'None',
}

interface CookieOptions {
  maxage?: number;
  path?: string;
  domain?: string;
  expires?: Date;
  secure?: boolean;
  samesite?: SameSite;
}

export const getCookie = (name: string) => {
  if (typeof document === 'undefined') return;

  const regexp = new RegExp(`(?:^|; )${name}=([^;]*)(?:$|; )`);
  const match = document?.cookie.match(regexp);
  return match ? decodeURIComponent(match[1]) : '';
};

export const setCookie = (
  name: string,
  value: string,
  options: CookieOptions,
) => {
  if (typeof document === 'undefined') {
    console.warn(
      'setCookie should only be called client-side',
      new Error('setCookie called outside of browser context.'),
    );
    return;
  }

  let str = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;
  if (options.maxage) {
    if (options.maxage < 0) {
      options.expires = new Date(0);
    } else {
      options.expires = new Date(new Date().getTime() + options.maxage);
    }
  }

  if (options.path) {
    str += `; path=${options.path}`;
  }

  if (options.domain) {
    let parts = options.domain.split('.');

    if (parts[0] === 'www') {
      parts = parts.slice(1);
    }

    const shopifyTld = parts.indexOf('shopify');
    if (shopifyTld >= 0) {
      parts = parts.slice(shopifyTld);
    }

    str += `; domain=.${parts.join('.')}`;
  }

  if (options.expires) {
    str += `; expires=${options.expires.toUTCString()}`;
  }

  if (options.samesite) {
    str += `; SameSite=${options.samesite}`;
  } else {
    str += `; SameSite=${SameSite.Lax}`;
  }
  // webkit + playwright fails silently on secure cookies
  // https://github.com/microsoft/playwright/issues/5215
  if (options.secure && !(window as any).E2E) {
    str += '; secure';
  }

  document.cookie = str;
};

export const isUrlFlagEnabled = (
  flag: string,
  url?: URL,
  values: string[] = ['true', '1'],
) => {
  let curUrl = url;
  if (!curUrl) {
    curUrl =
      typeof location === 'undefined' ? undefined : new URL(location.href);
  }
  const urlParam = curUrl?.searchParams.get(flag) || '';
  const foundFlag = values.find((value) => urlParam === value);
  return Boolean(foundFlag);
};

export const isCookieFlagEnabled = (
  flag: string,
  cookies?: {[key: string]: string},
) => {
  const cookieVal = cookies ? cookies[flag] : getCookie(flag);
  return cookieVal === 'true' || cookieVal === '1';
};

export const isUrlOrCookieFlagEnabled = (
  flag: string,
  url?: URL,
  cookies?: {[key: string]: string},
) => isUrlFlagEnabled(flag, url) || isCookieFlagEnabled(flag, cookies);

export const persistUrlFlagsAsCookies = (flag: string) => {
  // run debug flag code client-side to persist redesign state
  if (isUrlFlagEnabled(flag) && !isCookieFlagEnabled(flag)) {
    setCookie(flag, 'true', {
      domain: location.hostname,
      maxage: 7 * 24 * 60 * 60 * 1000,
      path: '/',
      secure: true,
    });
    // remove cookied if flag explicitly asks
  } else if (
    isUrlFlagEnabled(flag, undefined, ['0', 'false']) &&
    isCookieFlagEnabled(flag)
  ) {
    setCookie(flag, 'false', {
      domain: location.hostname,
      maxage: 0,
      path: '/',
      secure: true,
    });
  }
};
