import {stringify} from 'query-string';

import type {Site, LocalizedBlogsMap} from '../../../data/types';
import type {I18NOptions} from '../../types';
import {localizePathBySite} from '../../utils/site';
import {SIGNUP_BASE_URL} from '../../utils/signup/constants';
import blogsMapJson from '../../../data/localizedBlogsMap.json';

import {appHost, helpHost} from './constants';

export const linkpopUrl = (site: Site) => {
  switch (site.locale) {
    case 'id-ID':
    case 'pt-BR':
    case 'de':
    case 'es':
    case 'fr':
    case 'it':
    case 'ja':
      return `https://www.linkpop.com/${site.locale}`;
    case 'de-BE':
    case 'fr-BE':
    case 'fr-CA':
    case 'es-CO':
    case 'es-MX':
      return `https://www.linkpop.com/${site.languageCode}`;
    default:
      return 'https://www.linkpop.com/';
  }
};

// The switch statement is what is making this complex but it's the
// most straightforward way to do it.
// eslint-disable-next-line complexity
export const careersPath = ({site, localizePath}: I18NOptions) => {
  switch (site.locale) {
    case 'ja':
      return '/careers/search?locations%5B%5D=Japan';
    case 'da':
    case 'sv':
    case 'nl':
    case 'ko':
    case 'vi':
    case 'nl-BE':
    case 'en-BE':
    case 'de-BE':
    case 'fr-BE':
    case 'en-DK':
    case 'en-NO':
    case 'en-ID':
    case 'en-ZA':
    case 'es':
    case 'es-CO':
    case 'es-MX':
    case 'zh-CN':
    case 'zh-HK':
    case 'zh-TW':
      return '/careers';
    default:
      return localizePath('/careers');
  }
};

export const aboutPath = ({site, localizePath}: I18NOptions) => {
  switch (site.locale) {
    case 'es':
    case 'es-ES':
    case 'es-CO':
    case 'es-MX':
      return `/${site.pathPrefix}/acerca-de-nosotros`;
    case 'pt-BR':
      return `/${site.pathPrefix}/quem-somos`;
    default:
      return localizePath('/about');
  }
};

export const legalTermPrivacyNoPrefixLocales = [
  'da',
  'sv',
  'ko',
  'vi',
  'nl',
  'de-BE',
  'en-BE',
  'fr-BE',
  'nl-BE',
  'en-DK',
  'en-NO',
  'en-ID',
  'en-PH',
  'en-ZA',
];

export const legalTermPath = (site: Site) => {
  const {locale, languageCode} = site;
  let prefix = '/';
  let path = 'legal/terms';

  if (locale !== 'en' && !legalTermPrivacyNoPrefixLocales.includes(locale)) {
    prefix = `/${site.pathPrefix}/`;
  }
  if (
    languageCode === 'de' &&
    !legalTermPrivacyNoPrefixLocales.includes(locale)
  )
    path = 'legal/agb';
  if (languageCode === 'it') path = 'legal/termini';
  if (languageCode === 'es') path = 'legal/terminos';
  if (
    languageCode === 'fr' &&
    !legalTermPrivacyNoPrefixLocales.includes(locale)
  )
    path = 'legal/conditions';
  if (
    languageCode === 'nl' &&
    !legalTermPrivacyNoPrefixLocales.includes(locale)
  )
    path = 'juridisch/voorwaarden';
  if (languageCode === 'pt') path = 'legal/termos';

  return `${prefix}${path}`;
};

export const legalPrivacyPath = (site: Site) => {
  const {locale, languageCode} = site;
  if (locale === 'en' || legalTermPrivacyNoPrefixLocales.includes(locale)) {
    return '/legal/privacy';
  }

  let prefix = `/${site.pathPrefix}/`;
  let path = 'legal/privacy';

  if (languageCode === 'es') path = 'legal/privacidad';
  if (languageCode === 'fr') path = 'legal/confidentialite';
  if (languageCode === 'nl') path = 'juridisch/privacy';
  if (languageCode === 'pt') path = 'legal/privacidade';
  if (languageCode === 'de') path = 'legal/datenschutz';

  return `${prefix}${path}`;
};

export const legalCookiesPath = (site: Site) => {
  const {locale} = site;
  let prefix = '/';
  let path = 'legal/cookies';

  if (locale !== 'en' && !legalTermPrivacyNoPrefixLocales.includes(locale)) {
    prefix = `/${site.pathPrefix}/`;
  }

  return `${prefix}${path}`;
};

export const legalPath = (site: Site) => {
  switch (site.locale) {
    case 'en-DK':
    case 'en-BE':
    case 'en-NO':
    case 'en-ID':
    case 'en-PH':
    case 'en-ZA':
    case 'en-MY':
    case 'en-NG':
    case 'es':
    case 'es-CO':
    case 'es-MX':
      return '/legal';
    default:
      return localizePathBySite('/legal', site);
  }
};

export const pressUrl = ({site}: I18NOptions) => {
  return site.locale === 'fr'
    ? 'https://news.shopify.com/informations'
    : 'https://www.shopify.com/news';
};

export const themesUrl = (site: Site) => {
  const baseUrl = 'https://themes.shopify.com';
  switch (site.locale) {
    case 'es-ES':
    case 'fr-CA':
    case 'fr-BE':
    case 'de-BE':
    case 'nl-BE':
    case 'es-CO':
    case 'es-MX':
      return baseUrl + `?locale=${site.languageCode}`;
    case 'en-BE':
    case 'en-DK':
    case 'en-NO':
    case 'en-ID':
    case 'en-PH':
    case 'en-MY':
    case 'en-NG':
      return baseUrl;
    case 'zh-HK':
      return baseUrl + `?locale=zh-TW`;
    default:
      return baseUrl + `?locale=${site.locale}`;
  }
};

// The switch statement is what is making this complex but it's the
// most straightforward way to do it.
// eslint-disable-next-line complexity
export const appLocalizedUrl = (
  site: Site,
  path: string = '/',
  params?: {name: string; value: string}[],
) => {
  const base = 'https://apps.shopify.com';
  const baseUrl = new URL(path, base);
  if (params) {
    params.forEach(({name, value}) => {
      baseUrl.searchParams.append(name, value);
    });
  }
  switch (site.locale) {
    case 'pt-BR':
    case 'es':
    case 'it':
    case 'fr':
    case 'ja':
    case 'de':
    case 'da':
    case 'nl':
    case 'sv':
    case 'ko':
    case 'zh-CN':
    case 'zh-TW':
      baseUrl.searchParams.append('locale', site.locale);
      return baseUrl.toString();
    case 'es-ES':
    case 'es-CO':
    case 'es-MX':
    case 'de-BE':
    case 'fr-CA':
    case 'fr-BE':
    case 'nl-BE':
      baseUrl.searchParams.append('locale', site.languageCode);
      return baseUrl.toString();
    case 'zh-HK':
      baseUrl.searchParams.append('locale', 'zh-TW');
      return baseUrl.toString();
    default:
      return baseUrl.toString();
  }
};

export const hardwareUrl = (site: Site) => {
  // hardware store collapses several EU locales into one store that uses
  // the /eu/ prefix - handle those edge cases here
  if (['en-DK', 'en-BE', 'de-BE', 'fr-BE'].includes(site.locale)) {
    return `/eu/pos/store`;
  }

  const localizedPosPrefix =
    site.languageCode === 'fr' ||
    // && !['pt-PT'].includes(site.locale) is a temporary fix for the pt-PT locale only having one route
    (site.languageCode === 'pt' && !['pt-PT'].includes(site.locale))
      ? 'pdv'
      : 'pos';

  return `${
    site.pathPrefix ? `/${site.pathPrefix}` : ''
  }/${localizedPosPrefix}/store`;
};

export const merchantSupportUrl = (site: Site) => {
  switch (site.locale) {
    case 'nl':
    case 'de-BE':
    case 'zh-CN':
    case 'zh-HK':
    case 'zh-TW':
      return helpUrl(site, '/support/login');
    default:
      return helpUrl(site, '/questions');
  }
};

export const helpUrl = (site: Site, path = '/') => {
  let prefix = '';
  const helpLocales = [
    'da',
    'de',
    'en',
    'es',
    'fr',
    'it',
    'ja',
    'ko',
    'nl',
    'pt-BR',
    'sv',
    'vi',
    'zh-CN',
    'zh-TW',
  ];
  if (['zh-HK', 'zh-TW'].includes(site.locale)) {
    prefix = '/zh-TW';
  } else {
    const languageCode =
      site.languageCode || site.locale?.split('-')[0] || 'en';
    const found = helpLocales.find((locale) =>
      [site.locale, languageCode].includes(locale),
    );
    if (found) {
      prefix = `/${found}`;
    }
  }
  return `${helpHost}${prefix}${path}`;
};

export const blogPath = (page: string, site: Site) => {
  const localizedBlogs = (blogsMapJson as LocalizedBlogsMap)[site.locale];
  const initialPagePath = page.startsWith('/') ? page : `/${page}`;
  const localizedPageObj = localizedBlogs && localizedBlogs[initialPagePath];
  let pathPrefix: string;

  if (localizedPageObj && localizedPageObj.localPrefix) {
    pathPrefix = localizedPageObj.localPrefix;
  } else {
    pathPrefix = blogPathPrefix(site);
  }

  const resolvedPath = localizedPageObj?.localPath || initialPagePath;

  return `${pathPrefix}blog${
    resolvedPath.endsWith('/') ? resolvedPath.slice(0, -1) : resolvedPath
  }`;
};

const blogPathPrefix = (site: Site) => {
  switch (site.locale) {
    case 'nl-BE':
      return '/nl/';

    case 'de-BE':
      return '/de/';

    case 'fr-BE':
    case 'fr-CA':
      return '/fr/';

    case 'en-BE':
    case 'en-DK':
    case 'en-NO':
    case 'da':
    case 'sv':
      return '/';

    case 'zh-HK':
    case 'zh-TW':
      return '/zh/';

    case 'es-CO':
    case 'es-MX':
      return '/es/';

    default:
      return `${site.pathPrefix ? `/${site.pathPrefix}/` : '/'}`;
  }
};

export const inboxPathAlt = (site: Site) => {
  if (site.locale === 'fr-CA') {
    return `${appHost}/inbox?locale=fr`;
  }

  if (site.locale === 'es-ES') {
    return `${appHost}/inbox?locale=es`;
  }

  const locales = ['pt-BR', 'es', 'it', 'fr', 'ja', 'de'];
  if (locales.includes(site.locale)) {
    return `${appHost}/inbox?locale=${site.locale}`;
  }

  if (site.pathPrefix) {
    return localizePathBySite('/inbox', site);
  }

  return '/inbox';
};

export const learnPath = (site: Site) => {
  switch (site.locale) {
    case 'en-DK':
    case 'en-NO':
    case 'en-ID':
    case 'en-PH':
    case 'en-ZA':
    case 'en-MY':
    case 'en-NG':
      return '/learn';
    default:
      return localizePathBySite('/learn', site);
  }
};

export const editionsPath = (site: Site) => {
  switch (site.locale) {
    case 'nl':
    case 'en-BE':
    case 'nl-BE':
      return '/editions';
    case 'de-BE':
      return '/de/editions';
    case 'fr-BE':
      return '/fr/editions';
    default:
      return localizePathBySite('/editions', site);
  }
};

export const creatorsPath = (site: Site) => {
  switch (site.locale) {
    case 'nl':
    case 'nl-BE':
    case 'sv':
    case 'es':
    case 'es-CO':
    case 'es-MX':
    case 'ko':
    case 'vi':
    case 'zh-CN':
    case 'zh-HK':
    case 'zh-TW':
      return '/creators';
    case 'da':
      return '/dk-en/creators';
    default:
      return localizePathBySite('/creators', site);
  }
};

export const businessNameGeneratorPath = (site: Site) => {
  switch (site.locale) {
    case 'nl':
    case 'nl-BE':
      return localizePathBySite('/hulpmiddelen/bedrijfsnaam-generator', site);
    case 'en-DK':
    case 'en-NO':
    case 'en-ID':
    case 'en-PH':
    case 'en-ZA':
    case 'en-MY':
    case 'en-NG':
    case 'en-HK':
      return '/tools/business-name-generator';
    case 'fr-CA':
    case 'fr-BE':
      return '/fr/tools/business-name-generator';
    default:
      return localizePathBySite('/tools/business-name-generator', site);
  }
};

export const isToolsEnabled = (site: Site) => {
  return !['ko', 'vi', 'zh-HK', 'zh-TW'].includes(site.locale);
};

export const logoMakerPath = (site: Site) => {
  switch (site.locale) {
    case 'nl':
    case 'nl-BE':
    case 'en-DK':
    case 'en-NO':
    case 'en-ID':
    case 'en-PH':
    case 'en-ZA':
    case 'en-NG':
    case 'en-MY':
    case 'sv':
    case 'da':
    case 'ko':
    case 'vi':
    case 'zh-HK':
    case 'en-HK':
    case 'zh-TW':
      return '/tools/logo-maker';
    case 'fr-CA':
    case 'fr-BE':
      return '/fr/tools/logo-maker';
    default:
      return localizePathBySite('/tools/logo-maker', site);
  }
};

export const partnersPath = (site: Site) => {
  switch (site.locale) {
    case 'pt-BR':
      return '/br/parcerias';
    case 'da':
      return '/dk/partnere';
    case 'fr':
      return '/fr/partenaires';
    case 'fr-CA':
      return '/ca-fr/partenaires';
    case 'fr-BE':
      return '/be-fr/partenaires';
    case 'sv':
      return '/se/partners';
    default:
      return localizePathBySite('/partners', site);
  }
};

export const helpPartnersPath = (site: Site) => {
  return `${helpUrl(site)}${partnersPath(site).split('/').pop()}`;
};

export const signupName = 'start-free-trial';
export const signupUrl = (
  site: Site,
  canonicalUrl: string,
  handle: any,
  signUpTypes: string[] = [],
) => {
  if (handle.signupUrl) {
    return handle.signupUrl;
  }

  const params = {
    ...(handle.utmParams || {}),
    locale: site?.locale,
    language: site?.languageCode,
    signup_page: canonicalUrl,
  };
  let query = stringify(params);

  // parse canonicalUrl to get the signup_types[]
  const canonicalSignupTypes = new URLSearchParams(canonicalUrl).getAll(
    'signup_types[]',
  );
  canonicalSignupTypes.forEach((type: string) => {
    query += `&signup_types[]=${encodeURIComponent(type)}`;
  });

  (handle.signupTypes || []).concat(signUpTypes).forEach((type: string) => {
    query += `&signup_types[]=${encodeURIComponent(type)}`;
  });

  return `${SIGNUP_BASE_URL}?${query}`;
};

export const appUrl = (path: string, vertical: string) => {
  const query = stringify({
    st_source: 'brochure',
    utm_source: 'brochure',
    st_campaign: 'apps_on_brochure',
    utm_campaign: 'apps_on_brochure',
    st_content: `sell_${vertical}`,
    utm_content: `sell_${vertical}`,
  });
  return `${appHost}${path}?${query}`;
};

export const isShopPayAvailable = (site: Site) => {
  const shopPayUnavailableLocales = [
    'pt-BR',
    'en-IN',
    'en-NO',
    'en-ID',
    'en-MY',
    'en-PH',
    'en-NG',
    'en-ZA',
    'es-CO',
    'es-MX',
    'es',
    'zh-HK',
    'en-HK',
    'zh-TW',
    'ko',
    'vi',
    'zh-CN',
  ];

  return !shopPayUnavailableLocales.includes(site.locale);
};

export const getForumsUrl = (languageCode: string) => {
  const communityUrl = 'https://community.shopify.com';
  switch (languageCode) {
    case 'de':
      return `${communityUrl}/c/Shopify-Community-DE/ct-p/de`;
    case 'es':
      return `${communityUrl}/c/Comunidad-de-Shopify-ES/ct-p/es`;
    case 'fr':
      return `${communityUrl}/c/Communauté-Shopify-FR/ct-p/fr`;
    case 'it':
      return `${communityUrl}/c/Community-di-Shopify-IT/ct-p/it`;
    case 'ja':
      return `${communityUrl}/c/Shopify-Community-Japan-JP/ct-p/jp`;
    case 'nl':
      return `${communityUrl}/c/shopify-community-nl/ct-p/nl`;
    case 'pt':
      return `${communityUrl}/c/Comunidade-da-Shopify-PT-BR/ct-p/pt-br`;
    case 'zh-Hans':
      return `${communityUrl}/c/Shopify-Community-zh-CN/ct-p/zh-cn`;
    default:
      return `${communityUrl}/c/Shopify-Community/ct-p/en`;
  }
};

const freeTrialPathByLanguageCode: {[key: string]: string} = {
  da: 'gratis-trial',
  de: 'kostenloser-test',
  es: 'prueba-gratis',
  fr: 'essai-gratuit',
  nl: 'gratis-proef',
  pt: 'avaliacao-gratuita',
  sv: 'gratis-trial',
  it: 'prova-gratuita',
};

export const getFreeTrialUrlBySite = (site: Site) => {
  const pathPrefix = site.pathPrefix ? `/${site.pathPrefix}/` : '/';
  const path = freeTrialPathByLanguageCode[site.languageCode] || 'free-trial';

  return `${pathPrefix}${path}`;
};
