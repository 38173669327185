import {EXP_RETAIL_SIGNUP_TYPES} from '@/experiments';

import {useSiteData} from '../useSiteData';
import {useExperiment} from '../useExperiment';

export const useRetailSignupType = () => {
  const {enPath} = useSiteData();
  const expValue = useExperiment(EXP_RETAIL_SIGNUP_TYPES);
  const isRetailBlog = enPath?.startsWith('/retail');
  const isTreatment = expValue === 'treatment';

  return {
    signupType: isTreatment && isRetailBlog ? ['pos'] : [],
  };
};
