import type {Site} from '../../data/types';

import {Page} from './server/Page';
import {removePathPrefix} from './paths';

export function localizePathBySite(path: string, site: Site): string {
  const page = Page.byPath(path);
  if (!page) {
    return site.pathPrefix
      ? `/${site.pathPrefix}${removePathPrefix(path, `${site.pathPrefix}`)}`
      : path;
  }

  const {search, hash} = new URL(path, 'https://shopify.com');

  return page.localizedPath(site) + (search ? search : '') + (hash ? hash : '');
}

export function isPublishedOnSite(path: string, site: Site) {
  const page = Page.byPath(path);
  return page?.isPublishedOnSite(site);
}
