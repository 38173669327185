import {createContext, useContext} from 'react';

interface PageDataContextValue {
  vertical?: string;
  experimentVariationId?: string;
}

export const extractPageData = (data: any) => {
  const {vertical, experimentVariationId} = data || {};
  return {vertical, experimentVariationId};
};

export const PageDataContext = createContext<PageDataContextValue>({});

export function usePageData(): PageDataContextValue {
  return useContext(PageDataContext);
}
