import type {PricingDataContextValue} from '@data/types';

const pricingKeys = [
  'annualDiscountPercentage',
  'basicAnnualPrice',
  'googleCountryOffer',
  'minimumMonthlyPrice',
  'minimumMonthlyPriceCurrency',
  'paidTrialAmount',
  'paidTrialMonths',
  'posProPriceUsd',
  'posRetailLocations',
  'signupTypes',
  'promoAmount',
  'trialLength',
  'usd',
  'posProPriceEstimated',
];

export default function getPricingInterpolations(
  pricingData: PricingDataContextValue,
) {
  const {pricing} = pricingData;

  const interpolations = {};
  if (pricing === undefined) {
    return interpolations;
  }

  pricingKeys.forEach((key) => {
    (interpolations as any)[key] = (pricing as any)[key];
  });

  return interpolations;
}
